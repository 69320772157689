import { Notification } from 'rbx';
import React, { useEffect, useState } from 'react';

import { Loading } from '../../../components';
import qs from 'query-string';
import useAuth from '../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

function Verify({ onSuccess }) {
  const auth = useAuth();
  const loc = useLocation();
  const { token } = qs.parse(loc.search || {});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      setError('No token provided');
    } else {
      auth
        .verifyLogin(token)
        .then(onSuccess)
        .catch(err => {
          setError(err.message);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (error) {
    return <Notification color="danger">{error}</Notification>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return null;
}

export default Verify;
