import {
  Button,
  Column,
  Notification,
  Table,
  Title,
  Message,
  Delete,
} from 'rbx';
import React, { useState } from 'react';
import { css } from 'emotion';

import { Loading } from '../../../components';
import { format } from 'date-fns';
import useSubscriptions from '../../../hooks/useSubscriptions';
import useUser from '../../../hooks/useUser';

function Transactions() {
  const { user } = useUser();
  const {
    transactions = [],
    isLoading,
    refundTransaction,
    transactionError,
  } = useSubscriptions();
  const [selected, setSelected] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  if (
    !user ||
    (user.billingVersion !== 1 &&
      user.billingVersion !== 6 &&
      user.billingVersion !== 7 &&
      user.billingVersion !== 4)
  ) {
    return (
      <Notification color="info">
        There are no available transactions for this billing version
      </Notification>
    );
  }

  const refundHandler = transactionId => async () => {
    const confirm = await window.confirm(
      'Are you sure you want to refund this transaction?',
    );
    if (!confirm) return;
    try {
      if (processing) return;
      setProcessing(true);
      setSelected(transactionId);
      await refundTransaction(user.id, transactionId);
      setSuccess(true);
      setProcessing(false);
      setSelected(null);
    } catch (e) {
      setProcessing(false);
      setSelected(null);
    }
  };

  return (
    <div
      className={css`
        padding: 1em 0em;
      `}
    >
      <Column.Group>
        <Column size={'two-thirds'}>
          <Title size={3}>Transactions</Title>
          {success && (
            <Message color="success">
              <Message.Header>
                <p>Transaction has been successfully refunded.</p>
                <Delete as="button" onClick={() => setSuccess(false)} />
              </Message.Header>
            </Message>
          )}
          {transactionError ? (
            <Notification
              className={css`
                padding: 0.5em;
              `}
              color="danger"
            >
              {transactionError}
            </Notification>
          ) : (
            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Date</th>
                  {user.billingVersion !== 4 && <th>Action</th>}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {transactions.map(tx => (
                  <Table.Row key={tx.id}>
                    <Table.Cell>{tx.id}</Table.Cell>
                    <Table.Cell>{parseFloat(tx.amount)}</Table.Cell>
                    <Table.Cell>{tx.currency}</Table.Cell>
                    <Table.Cell>
                      {format(new Date(tx.createdAt), 'do MMM yyyy hh:mm a')}
                    </Table.Cell>
                    {user.billingVersion !== 4 && (
                      <Table.Cell>
                        <Button
                          size="small"
                          color="danger"
                          onClick={refundHandler(tx.id)}
                          disabled={processing || user.billingVersion === 4}
                        >
                          {processing && selected === tx.id
                            ? 'Refunding'
                            : 'Refund'}
                        </Button>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Column>
      </Column.Group>
    </div>
  );
}

export default Transactions;
