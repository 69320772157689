import { css } from 'emotion';
import { Title, Column } from 'rbx';
import React, { useEffect } from 'react';
import CoverLetters from './components/CoverLetters';
import useUser from '../../hooks/useUser';
import useCoverLetters from '../../hooks/useCoverLetters';

function CoverLetterPage() {
  const { user } = useUser();
  const { coverLetters, listCoverLetters, setCoverLetters } = useCoverLetters();

  useEffect(() => {
    if (user) {
      if (!coverLetters.length) {
        listCoverLetters(user.id);
      }
    } else {
      setCoverLetters([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      className={css`
        padding: 1em 0em;
      `}
    >
      <header
        className={css`
          padding: 0.2em 0em;
        `}
      >
        <Title size={3}>Cover letters</Title>
      </header>

      {user ? (
        <Column.Group>
          <Column size="two-thirds">
            <CoverLetters />
          </Column>
        </Column.Group>
      ) : (
        <Column.Group>
          <Column size="two-thirds">
            <p>No coverLetter found</p>
          </Column>
        </Column.Group>
      )}
    </div>
  );
}

export default CoverLetterPage;
