import { Column, Container, Notification, Title } from 'rbx';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import LoginForm from './components/LoginForm';
import Verify from './components/Verify';
import { css } from 'emotion';

function LoginPage() {
  const [isDone, setIsDone] = useState(false);
  const history = useHistory();

  const verifySuccessHandler = () => history.push('/');

  return (
    <Container>
      <Column.Group>
        <Switch>
          <Route exact path="/login/verify">
            <Column size="half" offset="one-quarter">
              <Title size={1}>Login</Title>
              <Title subtitle size={4}>
                Your login link is being verified
              </Title>
              <Verify onSuccess={verifySuccessHandler} />
            </Column>
          </Route>
          <Route path="/">
            <Column size="half" offset="one-quarter">
              <header
                className={css`
                  margin: 3em 0;
                `}
              >
                <Title size={1}>Login</Title>
                <Title subtitle size={4}>
                  A magic sign-in link will be sent to your admin account
                </Title>
              </header>
              {isDone ? (
                <Notification color="info">
                  Sent! Please check your email for a magic login link
                </Notification>
              ) : (
                <LoginForm onSuccess={setIsDone} />
              )}
            </Column>
          </Route>
        </Switch>
      </Column.Group>
    </Container>
  );
}

export default LoginPage;
