import { getTaskResultByTaskId } from './api';

export const getVatRateForEuropeanCountries = [
  { country: 'Austria', rate: 20 },
  { country: 'Belgium', rate: 21 },
  { country: 'Bulgaria', rate: 20 },
  { country: 'Croatia', rate: 25 },
  { country: 'Cyprus', rate: 19 },
  { country: 'Czech Republic', rate: 21 },
  { country: 'Czechia', rate: 21 },
  { country: 'Denmark', rate: 25 },
  { country: 'Estonia', rate: 20 },
  { country: 'Finland', rate: 24 },
  { country: 'France', rate: 20 },
  { country: 'Germany', rate: 19 },
  { country: 'Hungary', rate: 27 },
  { country: 'Greece', rate: 24 },
  { country: 'Ireland', rate: 23 },
  { country: 'Italy', rate: 22 },
  { country: 'Latvia', rate: 21 },
  { country: 'Lithuania', rate: 21 },
  { country: 'Luxembourg', rate: 16 },
  { country: 'Malta', rate: 18 },
  { country: 'Netherlands', rate: 21 },
  { country: 'Poland', rate: 23 },
  { country: 'Portugal', rate: 23 },
  { country: 'Romania', rate: 19 },
  { country: 'Slovakia', rate: 20 },
  { country: 'Slovenia', rate: 22 },
  { country: 'Spain', rate: 21 },
  { country: 'Sweden', rate: 25 },
  { country: 'United Kingdom', rate: 20 },
];

/**
 * Formats a number to currency parts.
 */
export const helperCurrencyFormatter = (price, currency) => {
  const splitCurrency = parseFloat(price, 10)
    .toFixed(2)
    .toString()
    .split('.');
  const integer = splitCurrency[0];
  const fraction = splitCurrency[1];
  switch (currency) {
    case 'ILS':
      return [
        { type: 'currency-prefix', value: '₪' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'THB':
      return [
        { type: 'currency-prefix', value: 'THB' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'BRL':
      return [
        { type: 'currency-prefix', value: 'R' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'RUB':
      return [
        { type: 'currency-prefix', value: 'RUB' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'CZK':
      return [
        { type: 'currency-prefix', value: 'CZK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'NOK':
      return [
        { type: 'currency-prefix', value: 'NOK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SAR':
      return [
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
        { type: 'currency-postfix', value: 'SAR' },
      ];
    case 'AED':
      return [
        { type: 'currency-prefix', value: 'AED' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'PHP':
      return [
        { type: 'currency-prefix', value: 'PHP' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'HUF':
      return [
        { type: 'currency-prefix', value: 'HUF' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'TRY':
      return [
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
        { type: 'currency-postfix', value: 'TL' },
      ];
    case 'HKD':
      return [
        { type: 'currency-prefix', value: 'HK' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'ARS':
      return [
        { type: 'currency-prefix', value: 'ARS' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'DKK':
      return [
        { type: 'currency-prefix', value: 'DKK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'PLN':
      return [
        { type: 'currency-prefix', value: 'PLN' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'MXN':
      return [
        { type: 'currency-prefix', value: 'MX' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SEK':
      return [
        { type: 'currency-prefix', value: 'SEK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SGD':
      return [
        { type: 'currency-prefix', value: 'S' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'CAD':
      return [
        { type: 'currency-prefix', value: 'C' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'NZD':
      return [
        { type: 'currency-prefix', value: 'NZ' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'EUR':
      return [
        { type: 'currency', value: '€' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: ',' },
        { type: 'fraction', value: fraction },
      ];
    case 'GBP':
      return [
        { type: 'currency', value: '£' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'AUD':
      return [
        { type: 'currency-prefix', value: 'A' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'INR':
      return [
        { type: 'currency', value: '₹' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'EGP':
      return [
        { type: 'currency', value: 'LE' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'USD':
    default:
      return [
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
  }
};

/**
 * Formats a number to currency with Intl.
 */
export const formatCurrency = (amount, currency) => {
  const formatter = helperCurrencyFormatter(+amount.toFixed(2), currency);
  const values = formatter.map(t => t.value);
  return values.join('');
};

export const getSiteURLString = domain => {
  switch (domain) {
    case 'cvdeboss':
      return 'cvdeboss.com';
    case 'mysmartcv':
      return 'mysmartcv.co';
    case 'buildmycv':
      return 'buildmycv.io';
    case 'curriculumlisto':
      return 'curriculumlisto.com';
    case 'bestonlineresume':
      return 'bestonlineresume.com';
    case 'modeles-cv':
      return 'modeles-cv.fr';
    case 'resume-example':
      return 'resume-example.com';
    case 'ejemplos-curriculum':
      return 'ejemplos-curriculum.com';
    case 'cvminta':
      return 'cvminta.com';
    case 'cvmenarik':
      return 'cvmenarik.com';
    case 'mau-cv':
      return 'mau-cv.com';
    case 'lebenslaufschreiben':
      return 'lebenslaufschreiben.com';
    case 'cv-wzor':
      return 'cv-wzor.com';
    case 'cv-skabelon':
      return 'cv-skabelon.com';
    case 'elegantcv':
      return 'elegantcv.app';
    case 'modellocv':
      return 'modellocv.com';
    case 'skriva-cv':
      return 'skriva-cv.com';
    case 'cveksempel':
      return 'cveksempel.com';
    case 'kazakhstan-cv':
      return 'kazakhstan-cv.com';
    case 'cv-pohja':
      return 'cv-pohja.com';
    case 'cvhazirlama':
      return 'cvhazirlama.cool';
    case 'cvforma':
      return 'cvforma.com';
    case 'cv-voorbeeld':
      return 'cv-voorbeeld.com';
    case 'resume-nation':
      return 'resume-nation.com';
    case 'brillantcv':
      return 'brillantcv.com';
    case 'cvvzor':
      return 'cvvzor.com';
    case 'xn--e1aaaggwcwefd4n':
      return 'резюмепример.com';
    case 'xn--mxabdxcg5aeo5c':
      return 'βιογραφικο.com';
    case 'thai-resume':
      return 'thai-resume.com';
    case 'criarcv':
      return 'criarcv.com';
    case 'cvparaugs':
      return 'cvparaugs.com';
    case 'cv-in-arabic':
      return 'cv-in-arabic.com';
    case 'creare-cv':
      return 'creare-cv.com';
    case 'modelos-de-curriculo':
      return 'modelos-de-curriculo.com';
    case 'cv-pavyzdys':
      return 'cv-pavyzdys.com';
    case 'cv-hebrew':
      return 'cv-hebrew.com';
    case 'contoh-resume':
      return 'contoh-resume.com';
    case 'cv-lite':
      return 'cv-lite.com';
    case 'cvtoolspro':
      return 'cvtoolspro.com';
    case 'resumizeme':
      return 'resumizeme.com';
    case 'theresumeranger':
      return 'theresumeranger.com';
    case 'cvkoostamine':
      return 'cvkoostamine.com';
    case 'zivotopis-primjer':
      return 'zivotopis-primjer.com';
    case 'hirethebest':
      return 'staging.hirethebest.io';
    case 'kreatorcv':
      return 'kreatorcv.com';
    case 'cvitaliano':
      return 'cvitaliano.com';
    case 'cvyarat':
      return 'cvyarat.app';
    case 'resumedone':
    default:
      return 'resumedone.co';
  }
};

const invoiceKeys = [
  'id',
  'billingVersion',
  'currency',
  'amount',
  'subscriptionId',
  'subscriptionAmount',
  'plan',
  'invoiceNo',
  'createdAt',
  'userId',
  'email',
  'firstName',
  'lastName',
  'country',
  'city',
  'domain',
];

export const reformatInvoiceData = data => {
  if (!Array.isArray(data)) return [];

  return data.map(invoice => {
    const correctObject = {};

    invoiceKeys.forEach(key => {
      correctObject[key] = invoice[key] !== undefined ? invoice[key] : 'N/A';
    });

    return correctObject;
  });
};

export const exportToCSV = (jsonData, fileName) => {
  if (!jsonData.length) {
    return;
  }

  // Extract the keys as column headers
  const headers = Object.keys(jsonData[0]).join(',');

  // Map through the data to create CSV rows
  const csvRows = jsonData.map(row =>
    Object.values(row)
      .map(value => `"${value}"`)
      .join(','),
  );

  // Combine headers and rows
  const csvData = [headers, ...csvRows].join('\n');

  // Create a Blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportToExcel = (jsonData, fileName) => {
  let table = `<table border="1">`;

  // Add table headers
  table += '<tr>';
  Object.keys(jsonData[0]).forEach(key => {
    table += `<th>${key}</th>`;
  });
  table += '</tr>';

  // Add table data
  jsonData.forEach(row => {
    table += '<tr>';
    Object.values(row).forEach(value => {
      table += `<td>${value}</td>`;
    });
    table += '</tr>';
  });

  table += '</table>';

  // Create a Blob from the table string
  const blob = new Blob([table], { type: 'application/vnd.ms-excel' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xls`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const reformatAndConvertPrices = invoices =>
  reformatInvoiceData(
    invoices.map(invoice => ({
      ...invoice,
      subscriptionAmount: invoice?.subscriptionAmount
        ? invoice.subscriptionAmount / 100
        : invoice.subscriptionAmount,
      amount: invoice?.amount ? invoice.amount / 100 : invoice.amount,
    })),
  );

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const pollTaskStatus = async (taskId, retryCount = 0) => {
  const taskResult = await getTaskResultByTaskId(taskId);

  if (taskResult?.status === 'processing') {
    if (retryCount >= 10) {
      return {
        invoices: [],
        success: false,
        message: 'Task failed! Please try again.',
      };
    }

    await delay(5000); // Wait for 5 seconds before retrying
    return pollTaskStatus(taskId, retryCount + 1);
  }

  return { invoices: taskResult, success: true, message: 'pooling success' };
};
