import React, { useState } from 'react';
import { format } from 'date-fns';
import { Column, Notification, Table, Button } from 'rbx';

import { Loading } from '../../../components';
import useCoverLetters from '../../../hooks/useCoverLetters';
import useUser from '../../../hooks/useUser';

function CoverLetters() {
  const [selected, setSelected] = useState(null);
  const {
    coverLetters,
    isLoading,
    downloadCoverLetter,
    isDownloading,
  } = useCoverLetters();
  const { user } = useUser();

  if (!user) return null;

  if (isLoading) {
    return <Loading />;
  }

  const clickHandler = ({ target }) => {
    const coverLetterId = target.dataset.value;
    setSelected(coverLetterId);
    downloadCoverLetter(user.id, coverLetterId);
  };

  return (
    <Column.Group>
      <Column size="full">
        {!coverLetters.length ? (
          <Notification color="info">
            There are no cover letters available for this user
          </Notification>
        ) : (
          <Table fullwidth striped hoverable>
            <Table.Head>
              <Table.Row>
                <th>ID</th>
                <th>Template</th>
                <th>Created on</th>
                <th>Updated on</th>
                <th>Actions</th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {coverLetters.map(coverLetter => (
                <Table.Row key={coverLetter.id}>
                  <Table.Cell title={coverLetter.id}>
                    {coverLetter.id.split('-')[0]}
                  </Table.Cell>
                  <Table.Cell>{coverLetter.settings.template}</Table.Cell>
                  <Table.Cell>
                    {format(new Date(coverLetter.createdAt), 'do MMM yyyy')}
                  </Table.Cell>
                  <Table.Cell>
                    {format(new Date(coverLetter.updatedAt), 'do MMM yyyy')}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      color="link"
                      size="small"
                      data-value={coverLetter.id}
                      onClick={clickHandler}
                      state={
                        isDownloading && selected === coverLetter.id
                          ? 'loading'
                          : ''
                      }
                      disabled={isDownloading && selected === coverLetter.id}
                    >
                      Download
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Column>
    </Column.Group>
  );
}

export default CoverLetters;
