import * as API from '../lib/api';

import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

const SubscriptionContext = createContext();

// Provider component that wraps your app and makes subscription object
// available to any child component that calls useSubscription().
export function SubscriptionProvider({ children, value }) {
  const subscription = useSubscriptionProvider(value);
  return (
    <SubscriptionContext.Provider value={subscription}>
      {children}
    </SubscriptionContext.Provider>
  );
}

SubscriptionProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

// Provider hook that creates subscription object and handles state
function useSubscriptionProvider(_subscriptionId) {
  const [subscription, setSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [transactionError, setTransactionError] = useState(null);

  // Gets a subscription for a user ID
  async function getSubscription(userId) {
    setIsLoading(true);
    setSubscriptionError(null);
    try {
      // Get subscription
      const subscription = await API.getSubscription(userId);
      setSubscription(subscription);
    } catch (e) {
      setSubscriptionError(e.message);
      resetSubscriptions();
    } finally {
      setIsLoading(false);
    }
  }

  // Gets a transaction for a user ID
  async function getTransaction(userId, billingVersion) {
    setIsLoading(true);
    setTransactionError(null);
    try {
      // Get transactions
      let transactions = await API.listTransactions(userId);
      if (billingVersion === 1) {
        transactions = transactions.map(t => ({
          ...t,
          amount: (t?.amount / 100)?.toFixed(2),
        }));
      }
      transactions.sort((a, b) => {
        a = new Date(a.createdAt);
        b = new Date(b.createdAt);
        if (a === b) return 0;
        if (a > b) {
          return -1;
        } else {
          return 1;
        }
      });
      setTransactions(transactions);
    } catch (e) {
      setTransactionError(e.message);
      resetTransactions();
    } finally {
      setIsLoading(false);
    }
  }

  function resetTransactions() {
    setTransactions([]);
  }

  // Cancels at the end of billing period
  async function cancelSubscription(userId, immediately = false) {
    setIsLoading(true);
    setSubscriptionError(null);
    try {
      await API.cancelSubscription(userId, immediately);
    } catch (e) {
      setSubscriptionError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  // Cancels at the end of billing period
  async function refundTransaction(userId, transactionId) {
    setTransactionError(null);
    try {
      await API.refundTransaction(userId, transactionId);
    } catch (e) {
      setTransactionError(e.message);
      throw e;
    }
  }

  function resetSubscriptions() {
    setSubscription(null);
  }

  // Return the subscription object and subscription methods
  return {
    isLoading,
    subscriptionError,
    transactionError,
    setSubscriptionError,
    setTransactionError,
    subscription,
    getSubscription,
    getTransaction,
    cancelSubscription,
    refundTransaction,
    transactions,
    resetSubscriptions,
    resetTransactions,
  };
}

// Hook for child components to get the subscription object
// and re-render when it changes.
const useSubscription = () => useContext(SubscriptionContext);
export default useSubscription;
