import React, { useState } from 'react';
import { Button, Control, Field, Help, Input, Title } from 'rbx';
import { Loading } from '../../../components';
import InvoiceData from './InvoiceData';
import useInvoice from '../../../hooks/useInvoice';
import isUUID from 'is-uuid';

const InvoiceInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const {
    invoice,
    getInvoiceByUserId,
    getInvoiceByEmailId,
    setInvoice,
  } = useInvoice();
  const changeHandler = ({ target: { value } }) => {
    if (!value) {
      setInvoice(null);
    }
    setValue(value);
  };

  async function submitHandler(e) {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      isUUID.anyNonNil(value)
        ? await getInvoiceByUserId(value)
        : await getInvoiceByEmailId(value);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Title size={5}>Find a invoice</Title>
      <form onSubmit={submitHandler}>
        <Field kind="addons" horizontal expanded>
          <Control expanded>
            <Input
              // disabled={isLoading}
              placeholder="Search by user ID or email"
              value={value}
              onChange={changeHandler}
              color={error ? 'danger' : ''}
            />
            {error && <Help color="danger">{error}</Help>}
          </Control>
          <Control>
            <Button type="submit" color="primary" disabled={isLoading}>
              Search
            </Button>
          </Control>
        </Field>
      </form>
      {isLoading ? (
        <Loading />
      ) : (
        invoice && value.length > 0 && <InvoiceData invoice={invoice} />
      )}
    </>
  );
};

export default InvoiceInfo;
