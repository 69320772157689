import { Control, Field, Input, Label, Column } from 'rbx';
import EditableField from './EditableField';
import React from 'react';
import RoleSelect from './RoleSelect';
import useUser from '../../../hooks/useUser';
import SubscriptionPage from '../../subscription/SubscriptionPage';
import ResumesPage from '../../resumes/ResumesPage';
import CoverLetterPage from '../../cover-letters/CoverLettersPage';

function AccountForm() {
  const { user } = useUser();
  if (!user) {
    return null;
  }

  const {
    id,
    emails,
    role,
    firstName,
    lastName,
    address,
    city,
    postalCode,
    phone,
    country,
  } = user;

  const primaryEmail = Array.isArray(emails) ? emails.find(e => e.primary) : '';

  return (
    <div>
      <Field>
        <Label>ID</Label>
        <Control>
          <Input name="id" value={id} readOnly disabled />
        </Control>
      </Field>
      <RoleSelect role={role} />
      <Column.Group>
        <Column size="half">
          <EditableField
            label="First name"
            name="firstName"
            value={firstName}
          />
        </Column>
        <Column size="half">
          <EditableField label="Last name" name="lastName" value={lastName} />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="half">
          <EditableField
            label="Email"
            name="email"
            value={primaryEmail ? primaryEmail.email : ''}
          />
        </Column>

        <Column size="half">
          <EditableField label="Address" name="address" value={address} />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="half">
          <EditableField label="City" name="city" value={city} />
        </Column>
        <Column size="half">
          <EditableField
            label="Postal code"
            name="postalCode"
            value={postalCode}
          />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="half">
          <EditableField
            label="Country"
            name="country"
            value={country ? country : ''}
          />
        </Column>
        <Column size="half">
          <EditableField label="Phone" name="phone" value={phone} />
        </Column>
      </Column.Group>
      <SubscriptionPage />
      <ResumesPage />
      <CoverLetterPage />
    </div>
  );
}

export default AccountForm;
