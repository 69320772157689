exports.Domains = [
  'resumedone',
  'cvdeboss',
  'mysmartcv',
  'buildmycv',
  'curriculumlisto',
  'bestonlineresume',
  'modeles-cv',
  'resume-example',
  'ejemplos-curriculum',
  'cvminta',
  'cvmenarik',
  'mau-cv',
  'lebenslaufschreiben',
  'cv-wzor',
  'cv-skabelon',
  'elegantcv',
  'modellocv',
  'skriva-cv',
  'cveksempel',
  'cv-pohja',
  'cvhazirlama',
  'cvforma',
  'cv-voorbeeld',
  'resume-nation',
  'brillantcv',
  'cvvzor',
  'xn--e1aaaggwcwefd4n',
  'xn--mxabdxcg5aeo5c',
  'thai-resume',
  'criarcv',
  'cvparaugs',
  'cv-in-arabic',
  'creare-cv',
  'modelos-de-curriculo',
  'cv-pavyzdys',
  'cv-hebrew',
  'contoh-resume',
  'cv-lite',
];
