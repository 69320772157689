import * as Icons from '@fortawesome/free-solid-svg-icons';

import { Button, Control, Field, Help, Input, Label } from 'rbx';
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import useUser from '../../../hooks/useUser';

// EditableField that saves to backend API.
function EditableField({ name, label, value, ...props }) {
  const { user, getUserById, changeEmail, updateUserField } = useUser();
  const [val, setVal] = useState(value);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function clickHandler() {
    setDisabled(!disabled);
    if (disabled || val === value) {
      return;
    }

    try {
      setLoading(true);

      // Email changes use a separate update method.
      // Everything else uses the user patch method.
      switch (name) {
        case 'email':
          await changeEmail(user.id, val);
          break;
        default:
          await updateUserField(user.id, name, val);
      }

      // Re-fetch updated user
      await getUserById(user.id);
      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Field>
      <Label>{label}</Label>
      <Field kind="addons" expanded>
        <Control expanded>
          <Input
            {...props}
            value={val}
            onChange={e => setVal(e.target.value)}
            disabled={disabled}
          />
          {error ? <Help color="danger">{error}</Help> : null}
        </Control>
        <Control>
          {disabled ? (
            <Button onClick={clickHandler} disabled={loading}>
              <FontAwesomeIcon
                icon={loading ? Icons.faSpinner : Icons.faEdit}
                className={css`
                  margin-right: 0.5em;
                `}
              />{' '}
              {loading ? 'Saving...' : 'Edit'}
            </Button>
          ) : (
            <Button onClick={clickHandler} color="info">
              <FontAwesomeIcon
                icon={Icons.faSave}
                className={css`
                  margin-right: 0.5em;
                `}
              />{' '}
              Save
            </Button>
          )}
        </Control>
      </Field>
    </Field>
  );
}

EditableField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

EditableField.defaultProps = {
  name: '',
  value: '',
  label: '',
};

export default EditableField;
