import { CircleSpinner } from 'react-spinners-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'emotion';

function Loading({ color, size }) {
  return (
    <div
      className={css`
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 2em 1em;
      `}
    >
      <CircleSpinner color={color} size={size} />
    </div>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Loading.defaultProps = {
  color: '#cccccc',
  size: 30,
};

export default Loading;
