import PropTypes from 'prop-types';
import React from 'react';

function Logo({ size }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <path
        fill="#167EE6"
        d="M496.106,324.207l-52.614-43.331c1.158-8.459,1.725-16.685,1.725-24.877s-0.568-16.417-1.725-24.877
	l52.614-43.331c6.311-5.198,7.936-14.169,3.851-21.237l-44.522-77.112c-4.074-7.079-12.666-10.162-20.313-7.279l-63.944,23.953
	c-13.156-10.129-27.548-18.432-42.963-24.799l-11.231-67.361C315.648,5.899,308.68,0,300.522,0h-89.043
	c-8.159,0-15.126,5.899-16.462,13.958l-11.231,67.361c-15.416,6.367-29.807,14.67-42.963,24.799L76.878,82.165
	c-7.624-2.894-16.228,0.2-20.313,7.279l-44.522,77.112c-4.085,7.068-2.46,16.039,3.851,21.237l52.614,43.331
	c-1.158,8.459-1.725,16.685-1.725,24.877s0.568,16.417,1.725,24.877l-52.614,43.331c-6.311,5.198-7.936,14.169-3.851,21.237
	l44.522,77.112c4.085,7.079,12.7,10.184,20.313,7.279l63.944-23.953c13.156,10.129,27.548,18.432,42.963,24.799l11.231,67.361
	c1.332,8.061,8.323,13.958,16.462,13.958h89.043c8.153,0,15.122-5.894,16.462-13.958l11.231-67.361
	c15.416-6.367,29.807-14.67,42.964-24.799l63.944,23.953c7.635,2.894,16.228-0.2,20.313-7.279l44.522-77.112
	C504.042,338.376,502.417,329.405,496.106,324.207z"
      />
      <path
        fill="#2860CC"
        d="M499.957,345.444l-44.522,77.112c-4.085,7.079-12.678,10.173-20.313,7.279l-63.944-23.953
	c-13.156,10.129-27.548,18.432-42.963,24.799l-11.231,67.361c-1.34,8.064-8.309,13.958-16.462,13.958h-43.82V0h43.82
	c8.159,0,15.126,5.899,16.462,13.958l11.231,67.361c15.416,6.367,29.807,14.67,42.964,24.799l63.944-23.953
	c7.647-2.883,16.239,0.2,20.313,7.279l44.522,77.112c4.085,7.068,2.46,16.039-3.851,21.237l-52.614,43.331
	c1.158,8.459,1.725,16.685,1.725,24.877s-0.568,16.417-1.725,24.877l52.614,43.331C502.417,329.405,504.042,338.376,499.957,345.444
	z"
      />
      <path
        fill="#FFAA00"
        d="M312.32,322.783v184.331c-3.039,3.039-7.235,4.886-11.798,4.886h-89.043
	c-3.929,0-7.58-1.369-10.463-3.695V322.783H312.32z"
      />
      <path
        fill="#F28D00"
        d="M312.32,322.783v184.331c-3.039,3.039-7.235,4.886-11.798,4.886H256.69V322.783H312.32z"
      />
      <path
        fill="#FFD500"
        d="M312.32,147.111v75.498L256.668,256l-55.652-33.391v-75.498
	c-40.897,20.836-66.781,62.392-66.783,108.886c-0.001,69.725,56.8,122.405,122.371,122.438
	c67.539,0.035,122.498-54.903,122.498-122.435C379.103,209.504,353.218,167.947,312.32,147.111z"
      />
      <path
        fill="#FFAA00"
        d="M379.103,256c0-46.496-25.886-88.053-66.783-108.889v75.498L256.668,256v122.431
	C324.181,378.43,379.103,323.511,379.103,256z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Logo.propTypes = {
  size: PropTypes.number,
};

Logo.defaultProps = {
  size: 40,
};

export default Logo;
