import { Column, Title } from 'rbx';
import React from 'react';
import { UserSearch } from '../../components';
import BaseLayout from '../../layouts/base';

function DashboardPage() {
  return (
    <BaseLayout>
      <Column.Group>
        <Column size="full">
          <Title size={3}>Admin</Title>
          {/* <Title subtitle size={4}>
                Welcome to your admin dashboard
              </Title> */}
        </Column>
      </Column.Group>

      <Column.Group>
        <Column size="half">
          <UserSearch />
        </Column>
      </Column.Group>
    </BaseLayout>
  );
}

export default DashboardPage;
