import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatCurrency } from '../lib/helper';

const styles = StyleSheet.create({
  container: {
    padding: 60,
  },
  section: {
    marginBottom: 15,
    marginTop: 15,
  },
  rightAlignedText: {
    textAlign: 'right',
    fontSize: 12,
  },
  text: {
    fontSize: 12,
  },
  boldText: {
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 15,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
  },
  tableRowTextV2: {
    width: '23.33%',
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 8,
    borderColor: 'black',
    fontSize: 12,
    height: '100%',
    paddingTop: '2px',
  },
  tableRowTextV1: {
    width: '17.5%',
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 8,
    borderColor: 'black',
    fontSize: 12,
    height: '100%',
    paddingTop: '2px',
  },
  tableRowTextDesc: {
    width: '30%',
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 8,
    borderColor: 'black',
    fontSize: 12,
    height: '100%',
    paddingTop: '2px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    borderBottomWidth: 1,
  },
  invoiceWrap: {
    marginTop: 12,
  },
});

const Invoice = ({ invoiceData }) => {
  console.log('invoiceData', invoiceData);
  const tableRow =
    invoiceData.vat > 0 ? styles.tableRowTextV1 : styles.tableRowTextV2;
  return (
    <Document>
      <Page size="A4" style={styles.container}>
        <View style={styles.section}>
          <Text style={styles.text}>{invoiceData?.websiteName}</Text>
          <Text style={styles.text}>{invoiceData?.compName} </Text>
          <Text style={styles.text}>{invoiceData?.compAdd} </Text>
          <Text style={styles.text}>
            {invoiceData?.compCity}
            {invoiceData?.compZipCode ? ', ' + invoiceData?.compZipCode : ''}
          </Text>
          <Text style={styles.text}>{invoiceData?.compCountry}</Text>
          <Text style={styles.text}>{invoiceData?.CompVatNo}</Text>
          <Text style={styles.text}>{invoiceData?.CompEmail}</Text>
          <View style={styles.invoiceWrap}>
            <Text style={styles.text}>{invoiceData?.invoiceDate}</Text>
            <Text style={styles.text}>{invoiceData?.invoiceNo}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.rightAlignedText}>**Bill To:**</Text>
          <Text style={styles.rightAlignedText}>{invoiceData?.custName}</Text>
          <Text style={styles.rightAlignedText}>{invoiceData?.custAdd}</Text>
          <Text style={styles.rightAlignedText}>
            {!!invoiceData?.custCity ? invoiceData?.custCity : ''}
            {!!invoiceData?.custZipCode ? ', ' + invoiceData?.custZipCode : ''}
          </Text>
          <Text style={styles.rightAlignedText}>
            {invoiceData?.custCountry}
          </Text>
          <Text style={styles.rightAlignedText}>{invoiceData?.custEmail}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.boldText}>**Invoice Details:**</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.tableRowTextDesc}>Description</Text>
            <Text style={tableRow}>Quantity</Text>
            <Text style={tableRow}>Unit Price</Text>
            {invoiceData?.vat > 0 && <Text style={tableRow}>VAT</Text>}
            <Text style={tableRow}>TOTAL</Text>
          </View>
          <View style={[styles.row, { height: '30px' }]}>
            <Text style={styles.tableRowTextDesc}>{invoiceData?.custPlan}</Text>
            <Text style={tableRow}>1</Text>
            <Text style={tableRow}>
              {formatCurrency(+invoiceData?.unitAmt, invoiceData?.custCurrency)}
            </Text>
            {invoiceData?.vat > 0 && (
              <Text style={tableRow}>
                {formatCurrency(+invoiceData?.vat, invoiceData?.custCurrency)}
              </Text>
            )}
            <Text style={tableRow}>
              {formatCurrency(+invoiceData?.custAmt, invoiceData?.custCurrency)}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            Thank you for choosing {invoiceData?.websiteName}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
