import { Control, Field, Help, Icon, Label, Select } from 'rbx';
import React, { useState } from 'react';
import { faSpinner, faUserTag } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import useUser from '../../../hooks/useUser';

function RoleSelect({ role }) {
  const { user, getUserById, changeRole } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function changeHandler(e) {
    try {
      setLoading(true);

      // Update role
      await changeRole(user.id, e.target.value);
      // Re-fetch updated user
      await getUserById(user.id);
      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Field>
      <Label>Role</Label>
      <Control iconLeft>
        <Select.Container>
          <Select value={role} onChange={changeHandler} disabled={loading}>
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="paid_user">Paid User</Select.Option>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="agent">Agent</Select.Option>
          </Select>
        </Select.Container>
        <Icon size="small" align="left">
          <FontAwesomeIcon icon={loading ? faSpinner : faUserTag} />
        </Icon>
      </Control>
      {error ? <Help color="danger">{error}</Help> : null}
    </Field>
  );
}

RoleSelect.propTypes = {
  role: PropTypes.string.isRequired,
};

RoleSelect.defaultProps = {
  role: 'user',
};

export default RoleSelect;
