import { Button, Column, Field, Help, Input, Label, Select } from 'rbx';
import { ColumnGroup } from 'rbx/grid/columns/column-group';
import React, { useState } from 'react';
import {
  exportToCSV,
  exportToExcel,
  pollTaskStatus,
  reformatAndConvertPrices,
} from '../../../lib/helper';
import { getInvoiceByDateRange } from '../../../lib/api';

const InvoiceDateRangeForm = () => {
  const [exportParameters, setExportParameters] = useState({
    startDate: null,
    endDate: null,
    exportType: 'excel',
  });

  const [status, setStatus] = useState({
    error: null,
    loading: false,
    message: null,
  });

  const onChange = e => {
    const { name, value } = e.target;
    setExportParameters(prev => ({ ...prev, [name]: value }));
    setStatus({
      error: null,
      loading: false,
      message: null,
    });
  };

  const handleSubmit = async () => {
    try {
      setStatus(prev => ({ ...prev, loading: true }));

      const { startDate, endDate, exportType } = exportParameters || {};

      if (!startDate || !endDate || !exportType) {
        return handleError('Please Select the Date range and File format!');
      }

      const { taskId } = await getInvoiceByDateRange({ startDate, endDate });

      if (!taskId) {
        return handleError('Failed to retrieve task ID');
      }

      const { invoices, success, message } = await pollTaskStatus(taskId);

      if (!success) return handleError(message);

      if (!invoices || invoices.length === 0) {
        return handleError('No Invoices found for entered dates');
      }

      const formattedInvoices = reformatAndConvertPrices(invoices);

      if (exportType === 'excel') {
        exportToExcel(formattedInvoices, `${startDate}-${endDate}`);
      } else {
        exportToCSV(formattedInvoices, `${startDate}-${endDate}`);
      }

      return setStatus({ error: null, loading: false, message: null });
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = message => {
    setStatus(prev => ({
      ...prev,
      loading: false,
      error: message,
    }));
    return false;
  };

  return (
    <>
      <ColumnGroup>
        <Column size="one-quarter">
          <Field>
            <Label>Start Date</Label>
            <Input
              type="date"
              name="startDate"
              value={exportParameters.startDate}
              onChange={e => onChange(e)}
              disabled={status?.loading}
            />
          </Field>
        </Column>
        <Column size="one-quarter">
          <Field>
            <Label>Ending Date</Label>
            <Input
              type="date"
              name="endDate"
              value={exportParameters.endDate}
              onChange={e => onChange(e)}
              disabled={status?.loading}
            />
          </Field>
        </Column>
        <Column size={'one-quarter'}>
          <Field>
            <Label>Select Format</Label>
            <Select.Container>
              <Select
                value={exportParameters.exportType}
                name="exportType"
                onChange={e => onChange(e)}
                disabled={status?.loading}
              >
                <Select.Option value="excel">Excel</Select.Option>
                <Select.Option value="csv">CSV</Select.Option>
              </Select>
            </Select.Container>
          </Field>
        </Column>
      </ColumnGroup>
      <Button
        type="submit"
        color="primary"
        onClick={() => handleSubmit()}
        disabled={status?.loading}
      >
        Export
      </Button>

      {status?.error && <Help color="danger">{status?.error}</Help>}
    </>
  );
};

export default InvoiceDateRangeForm;
