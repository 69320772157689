import { Column, Title } from 'rbx';
import BaseLayout from '../../layouts/base';
import { ColumnGroup } from 'rbx/grid/columns/column-group';
import InvoiceDateRangeForm from './components/InvoiceDateRangeForm';

function ExportInvoices() {
  return (
    <BaseLayout>
      <Column.Group>
        <Column size="full">
          <Title size={3}>Export Invoices</Title>
        </Column>
      </Column.Group>

      <ColumnGroup>
        <Column size="full">
          <InvoiceDateRangeForm />
        </Column>
      </ColumnGroup>
    </BaseLayout>
  );
}

export default ExportInvoices;
