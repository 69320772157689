import React, { useState } from 'react';
import { format } from 'date-fns';
import { Column, Notification, Table, Button } from 'rbx';

import { Loading } from '../../../components';
import useResumes from '../../../hooks/useResumes';
import useUser from '../../../hooks/useUser';

function Resumes() {
  const [selected, setSelected] = useState(null);
  const { resumes, isLoading, downloadResume, isDownloading } = useResumes();
  const { user } = useUser();

  if (!user) return null;

  if (isLoading) {
    return <Loading />;
  }

  const clickHandler = ({ target }) => {
    const resumeId = target.dataset.value;
    setSelected(resumeId);
    downloadResume(user.id, resumeId, user.gender);
  };

  return (
    <Column.Group>
      <Column size="full">
        {!resumes.length ? (
          <Notification color="info">
            There are no resumes available for this user
          </Notification>
        ) : (
          <Table fullwidth striped hoverable>
            <Table.Head>
              <Table.Row>
                <th>ID</th>
                <th>Template</th>
                <th>Created on</th>
                <th>Updated on</th>
                <th>Actions</th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {resumes.map(resume => (
                <Table.Row key={resume.resumeId}>
                  <Table.Cell title={resume.resumeId}>
                    {resume.resumeId.split('-')[0]}
                  </Table.Cell>
                  <Table.Cell>{resume.settings.template}</Table.Cell>
                  <Table.Cell>
                    {typeof resume.createdAt === 'object'
                      ? 'Unknown'
                      : format(new Date(resume.createdAt), 'do MMM yyyy')}
                  </Table.Cell>
                  <Table.Cell>
                    {resume.updatedAt
                      ? format(new Date(resume.updatedAt), 'do MMM yyyy')
                      : 'Unknown'}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      color="link"
                      size="small"
                      data-value={resume.resumeId}
                      onClick={clickHandler}
                      state={
                        isDownloading && selected === resume.resumeId
                          ? 'loading'
                          : ''
                      }
                      disabled={isDownloading && selected === resume.resumeId}
                    >
                      Download
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Column>
    </Column.Group>
  );
}

export default Resumes;
