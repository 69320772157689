import { css } from 'emotion';
import { Button, Column, Notification } from 'rbx';
import React, { useEffect } from 'react';
import { UserCard } from '../../components';
import useUser from '../../hooks/useUser';
import AccountForm from './components/AccountForm';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import isUUID from 'is-uuid';
import BaseLayout from '../../layouts/base';

function AccountPage() {
  const { user, getUserById, getUserByEmail, setUser, error } = useUser();
  const history = useHistory();
  const loc = useLocation();
  const { email } = qs.parse(loc.search || {});

  const findUser = async () => {
    const getUser = isUUID.anyNonNil(email) ? getUserById : getUserByEmail;
    await getUser(encodeURI(email));
  };

  useEffect(() => {
    if (email) findUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <BaseLayout>
      <div
        className={css`
          padding: 1em 1.5em;
        `}
      >
        {/* <header
          className={css`
            margin-bottom: 1em;
          `}
        >
          <Column.Group>
            <Column>
              <Title size={3}>Account</Title>
            </Column>
            <Column>
              <div
                onClick={logoutHandler}
                className={css`
                  margin-left: auto;
                  width: fit-content;
                  cursor: pointer;
                `}
              >
                <FontAwesomeIcon icon={Icons.faSignOutAlt} />{' '}
                <Generic as="span" textColor="danger">
                  Logout
                </Generic>
              </div>
            </Column>
          </Column.Group>
        </header> */}
        <Button
          color="info"
          className={css`
            margin-bottom: 2em;
          `}
          onClick={() => {
            setUser(null);
            history.push('/');
          }}
        >
          {' '}
          Back{' '}
        </Button>
        {error ? (
          <Notification color="danger">{error}</Notification>
        ) : (
          <Column.Group>
            <Column size="two-thirds">
              <AccountForm />
            </Column>
            <Column size={user ? 'one-third' : 'two-thirds'}>
              <UserCard />
            </Column>
          </Column.Group>
        )}
      </div>
    </BaseLayout>
  );
}

export default AccountPage;
