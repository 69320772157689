import Cookies from 'cookies-js';
import qs from 'query-string';

// Creates an authenticatd HTTP request
async function createRequest({
  url,
  method = 'GET',
  headers = {},
  body,
  query,
  userId, // Override
}) {
  const token = Cookies.get('AuthToken');
  if (!token) {
    throw new Error('AuthToken not set');
  }

  const options = {
    method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  // Add admin override user ID
  if (userId) {
    options.headers['x-user-id'] = userId;
  }
  if (body) {
    options.body = JSON.stringify(body);
  }

  let _url = url;
  if (query) {
    _url = `${_url}?${qs.stringify(query)}`;
  }

  const res = await fetch(_url, options);

  const contentType = res.headers.get('Content-Type');
  const bufferContentTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
  ];

  if (bufferContentTypes.includes(contentType)) {
    return res.arrayBuffer();
  }

  const text = await res.text();

  if (res.status > 399) {
    throw new Error(text);
  }

  try {
    const json = JSON.parse(text);
    return json;
  } catch (e) {
    return text;
  }
}

// Creates an HTTP request to the API.
export const createAPIRequest = ({ path, ...rest }) =>
  createRequest({ url: `${process.env.REACT_APP_API_URL}${path}`, ...rest });

// Creates an HTTP request to the Billing API.
export const createBillingRequest = ({ path, ...rest }) =>
  createRequest({
    url: `${process.env.REACT_APP_BILLING_URL}${path}`,
    ...rest,
  });

// Creates an HTTP request to the Webapp API.
export const createWebappRequest = ({ path, ...rest }) =>
  createRequest({ url: `${process.env.REACT_APP_WEBAPP_URL}${path}`, ...rest });

// Creates an HTTP request to the Cover Letters API.
export const createCoverLettersRequest = ({ path, ...rest }) =>
  createRequest({
    url: `${process.env.REACT_APP_COVER_LETTERS_ENDPOINT}${path}`,
    ...rest,
  });

export const createAirtableRequest = async ({ path, ...rest }) => {
  try {
    const url = `${process.env.REACT_APP_AIRTABLE_URL}${path}`;
    const response = await fetch(encodeURI(url), {
      method: rest.method,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
      },
    })
      .then(response => response.json())
      .then(data => data);

    if (!response.records.length) {
      console.log('record not found');
    }
    return response.records[0].fields;
  } catch (e) {
    throw e;
  }
};
