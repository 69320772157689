import React, { useState, useEffect } from 'react';
import { Button, Control, Field, Help, Input, Title, Select } from 'rbx';
import { css } from 'emotion';
import * as API from '../../../lib/api';
import UserData from './UserData';
import { Loading } from '../../../components';
import { Domains } from '../../../contants';

const UserInfo = () => {
  const [user, setUser] = useState([]);
  const [isUserSet, setIsUserSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const [domain, setDomain] = useState('');
  const [filteredUser, setFilteredUser] = useState(user);

  const changeHandler = ({ target: { value } }) => {
    setValue(value);
    if (value.length === 0) setIsUserSet(false);
  };

  const domainHandler = ({ target: { value } }) => {
    setDomain(value);
  };
  async function submitHandler(e) {
    e.preventDefault();
    setIsLoading(true);
    setIsUserSet(true);
    setError(null);
    try {
      const users = await API.searchUser(value, 0, 100);
      setIsUserSet(true);
      setUser(users || []);
      setFilteredUser(users || []);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (domain) {
      setFilteredUser(user.filter(user => user._source.domain === domain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  return (
    <>
      <Title size={5}>Find a user</Title>
      <form onSubmit={submitHandler}>
        <Field kind="addons" horizontal expanded>
          <Control expanded>
            <Input
              // disabled={isLoading}
              placeholder="Search by value"
              value={value}
              onChange={changeHandler}
              color={error ? 'danger' : ''}
            />
            {error && <Help color="danger">{error}</Help>}
          </Control>
          <Control>
            <Button type="submit" color="primary" disabled={isLoading}>
              Search
            </Button>
          </Control>
        </Field>
        <Control>
          <Select.Container
            className={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Select value={domain} onChange={domainHandler}>
              <Select.Option value="">Select Domain</Select.Option>
              {Domains.map((el, index) => (
                <Select.Option value={el} key={index}>
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Control>
      </form>
      {isLoading ? (
        <Loading />
      ) : (
        isUserSet && value.length > 0 && <UserData user={filteredUser} />
      )}
    </>
  );
};

export default UserInfo;
