import { Table, Notification } from 'rbx';
import React from 'react';
import { css } from 'emotion';

const UserData = ({ user }) => {
  const users = user?.map(u => u._source);
  return (
    <>
      {!user.length ? (
        <Notification
          color="info"
          className={css`
            margin-top: 2em;
          `}
        >
          There are no available user with searched value
        </Notification>
      ) : (
        <Table
          fullwidth
          striped
          hoverable
          className={css`
            margin-top: 2em;
          `}
        >
          <Table.Head>
            <Table.Row>
              <th>ID</th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Email</th>
              <th>Domain</th>
              <th>Role</th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {users.map(u => (
              <Table.Row key={u.id}>
                <Table.Cell>{u.id}</Table.Cell>
                <Table.Cell>{u.firstName}</Table.Cell>
                <Table.Cell>{u.lastName}</Table.Cell>
                <Table.Cell>{u.email}</Table.Cell>
                <Table.Cell>{u.domain}</Table.Cell>
                <Table.Cell>{u.role}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
export default UserData;
