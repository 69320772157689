import * as Icons from '@fortawesome/free-solid-svg-icons';

import { Button, Notification, Title } from 'rbx';
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '../../../components';
import { css } from 'emotion';
import useSubscriptions from '../../../hooks/useSubscriptions';
import useUser from '../../../hooks/useUser';

// const iconCSS = css`
//   color: #999999;
//   margin-right: 0.5em;
// `;

function InfoPanel() {
  const { user, getUserById } = useUser();
  const {
    subscription,
    isLoading,
    cancelSubscription,
    getSubscription,
  } = useSubscriptions();
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(false);

  async function cancelClickHandler() {
    if (!cancelConfirm) {
      return setCancelConfirm(true);
    }
    // Cancel at end of billing period
    await cancelSubscription(user.id);
    // Reset confirm
    setCancelConfirm(false);
    // Refetch user
    await getUserById(user.id);
    // Refetch subscription
    await getSubscription(user.id);
  }

  async function disableClickHandler() {
    if (!disableConfirm) {
      return setDisableConfirm(true);
    }
    // Cancel immediately
    await cancelSubscription(user.id, true);
    // Reset confirm
    setDisableConfirm(false);
    // Refetch user
    await getUserById(user.id);
    // Refetch subscription
    await getSubscription(user.id);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (subscription) {
    const {
      // createdAt,
      expiresAt,
      // updatedAt,
      // isMonthly,
      isActive,
      // countryCode,
      // planId,
      cancelledAt,
    } = subscription;

    // const startDate = new Date(createdAt);
    // const endDate = expiresAt ? new Date(expiresAt) : null;
    // const updateDate = updatedAt ? new Date(updatedAt) : null;

    return (
      <>
        {isActive ? (
          <Notification
            className={css`
              padding: 0.5em;
            `}
            color={expiresAt || cancelledAt ? 'warning' : 'success'}
          >
            <Title subtitle size={4}>
              {expiresAt || cancelledAt ? (
                <>
                  <FontAwesomeIcon icon={Icons.faStopCircle} /> Cancelled
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={Icons.faCheckCircle} /> Active
                  subscription
                </>
              )}
            </Title>
          </Notification>
        ) : (
          <Notification color="danger">
            <Title subtitle size={4}>
              <FontAwesomeIcon icon={Icons.faTimesCircle} /> Inactive
              subscription
            </Title>
          </Notification>
        )}
        {/* <Table fullwidth>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <strong>
                  <FontAwesomeIcon
                    className={iconCSS}
                    icon={Icons.faCalendarPlus}
                  />
                  Start Date
                </strong>
              </Table.Cell>
              <Table.Cell>{format(startDate, 'do MMM yyyy')}</Table.Cell>
            </Table.Row>

            {expiresAt ? (
              <Table.Row>
                <Table.Cell>
                  <FontAwesomeIcon
                    className={iconCSS}
                    icon={Icons.faCalendarTimes}
                  />
                  <strong>End Date</strong>
                </Table.Cell>
                <Table.Cell>{format(endDate, 'do MMM yyyy')}</Table.Cell>
              </Table.Row>
            ) : null}

            {updatedAt ? (
              <Table.Row>
                <Table.Cell>
                  <FontAwesomeIcon
                    className={iconCSS}
                    icon={Icons.faCalendar}
                  />
                  <strong>Last update</strong>
                </Table.Cell>
                <Table.Cell>{format(updateDate, 'do MMM yyyy')}</Table.Cell>
              </Table.Row>
            ) : null}

            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon className={iconCSS} icon={Icons.faRedo} />
                <strong>Billing cycle</strong>
              </Table.Cell>
              <Table.Cell>{isMonthly ? 'Monthly' : 'Yearly'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon
                  className={iconCSS}
                  icon={Icons.faGlobeEurope}
                />
                <strong>Country code</strong>
              </Table.Cell>
              <Table.Cell>{countryCode || 'N/A'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                <FontAwesomeIcon className={iconCSS} icon={Icons.faReceipt} />
                <strong>Plan ID</strong>
              </Table.Cell>
              <Table.Cell>
                <a
                  href="https://airtable.com/tbl5ndfNRvn4M69wj/viwGSVWjMmztqXxfq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <code>{planId}</code>
                </a>
                <FontAwesomeIcon
                  className={iconCSS}
                  icon={Icons.faExternalLinkAlt}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table> */}

        {isActive ? (
          <Button.Group>
            {!expiresAt && (
              <Button
                onClick={cancelClickHandler}
                color="danger"
                title="Cancel at end of billing period"
              >
                <FontAwesomeIcon
                  icon={Icons.faTimesCircle}
                  className={css`
                    margin-right: 0.5em;
                  `}
                />
                {cancelConfirm
                  ? 'Are you sure? Click to confirm'
                  : 'Cancel subscription'}
              </Button>
            )}
            <Button
              color="danger"
              outlined={!expiresAt}
              title="Set to inactive immediately"
              onClick={disableClickHandler}
            >
              <FontAwesomeIcon
                icon={Icons.faTimesCircle}
                className={css`
                  margin-right: 0.5em;
                `}
              />
              {disableConfirm
                ? 'Are you sure? Click to confirm'
                : 'Cancel immediately'}
            </Button>
          </Button.Group>
        ) : null}
      </>
    );
  }

  return (
    <Notification>
      <Title subtitle size={4}>
        <FontAwesomeIcon icon={Icons.faBan} /> Not subscribed
      </Title>
    </Notification>
  );
}

export default InfoPanel;
