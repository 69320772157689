import * as Pages from './pages';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { PrivateRoute } from './components';
import React from 'react';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Pages.Login />
        </Route>
        <PrivateRoute path="/account">
          <Pages.Account />
        </PrivateRoute>
        <PrivateRoute path="/user">
          <Pages.User />
        </PrivateRoute>
        <PrivateRoute path="/invoice">
          <Pages.Invoice />
        </PrivateRoute>
        <PrivateRoute path="/export-invoices">
          <Pages.ExportInvoice />
        </PrivateRoute>
        <PrivateRoute path="/">
          <Pages.Dashboard />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
