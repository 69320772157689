import * as Icons from '@fortawesome/free-solid-svg-icons';
import { css } from 'emotion';
import { Button, Notification } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import useUser from '../../../hooks/useUser';
import * as API from '../../../lib/api';

function DeleteAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [error, setError] = useState(null);

  const { user, setUser } = useUser();

  const clickHandler = async () => {
    if (!cancelConfirm) {
      return setCancelConfirm(true);
    }

    setIsLoading(true);

    try {
      await API.deleteAccount(user.id);
      setError(null);
      setUser(null);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  return (
    <div
      className={css`
        margin: 1em 0 1em;
      `}
    >
      {/* <Title size={5}>Delete Account</Title>
      <Title subtitle size={6}>
        It will remove all resumes and cancel subscription of this user.
      </Title> */}
      <div>
        <Button
          color="danger"
          state={isLoading ? 'loading' : ''}
          onClick={clickHandler}
          disabled={isLoading}
        >
          <FontAwesomeIcon
            icon={Icons.faTimesCircle}
            className={css`
              margin-right: 0.5em;
            `}
          />
          {cancelConfirm ? 'Are you sure? Click to confirm' : 'Remove User'}
        </Button>

        {error ? <Notification color="danger">{error}</Notification> : null}
      </div>
    </div>
  );
}

export default DeleteAccount;
