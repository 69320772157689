import { css } from 'emotion';
import { Button, Notification } from 'rbx';
import React, { useState } from 'react';

import useUser from '../../../hooks/useUser';
import * as API from '../../../lib/api';
import { CopyIcon } from '../../../components/CopyIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from 'react-simple-tooltip';

function MagicLinkCreator() {
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(null);
  const [error, setError] = useState(null);

  const { user } = useUser();

  const clickHandler = async () => {
    setIsLoading(true);
    setLink(null); // reset

    try {
      const { link } = await API.createMagicLink(user.id);
      setLink(link);
      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={css`
        margin: 1em 0 1em;
      `}
    >
      {/* <Title size={5}>Magic Link</Title> */}
      {/* <Title subtitle size={6}>
        Create a new login link for the user that is valid for{' '}
        <strong>1 hour</strong>.
      </Title> */}
      <div>
        <Button
          color="info"
          state={isLoading ? 'loading' : ''}
          onClick={clickHandler}
          disabled={isLoading}
        >
          Generate link
        </Button>
        {link ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              margin: '1rem 0',
              background: '#ebe9e9',
              padding: '1rem',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                display: 'block',
                wordWrap: 'break-word',
                width: '200px',
                flex: 1,
              }}
            >
              {link}
            </div>
            <CopyToClipboard text={link} onCopy={() => {}}>
              <Tooltip content={'Copy link'}>
                <div style={{ cursor: 'pointer' }}>
                  <CopyIcon />
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
        ) : null}

        {error ? <Notification color="danger">{error}</Notification> : null}
      </div>
    </div>
  );
}

export default MagicLinkCreator;
