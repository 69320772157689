import { Table, Notification, Button } from 'rbx';
import React, { useState } from 'react';
import { css } from 'emotion';
import { pdf } from '@react-pdf/renderer';
import Invoice from '../../../components/invoice';
import useInvoice from '../../../hooks/useInvoice';
import { saveAs } from 'file-saver';

const InvoiceData = ({ invoice }) => {
  const [loading, setLoading] = useState(false);
  const { invoiceData, getCompanyData } = useInvoice();

  async function downloadHandler(data) {
    setLoading(true);
    try {
      setLoading(true);
      const invoiceInfo = await getCompanyData(data);
      if (invoiceInfo || invoiceData) {
        const blob = await pdf(
          <Invoice invoiceData={invoiceInfo || invoiceData} />,
        ).toBlob();
        saveAs(blob, 'invoice');
      }
      setLoading(false);
    } catch (e) {
      console.log('error', e);
    }
  }
  return (
    <>
      {!invoice?.length ? (
        <Notification
          color="info"
          className={css`
            margin-top: 2em;
          `}
        >
          There are no available invoice with searched value
        </Notification>
      ) : (
        <Table
          fullwidth
          striped
          hoverable
          className={css`
            margin-top: 2em;
          `}
        >
          <Table.Head>
            <Table.Row>
              <th>user ID</th>
              <th>email</th>
              <th>Invoice No</th>
              <th>plan</th>
              <th>Amount</th>
              <th>currency</th>
              <th>Action</th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {invoice?.map(u => (
              <Table.Row key={u.id}>
                <Table.Cell>{u.userId}</Table.Cell>
                <Table.Cell>{u.email}</Table.Cell>
                <Table.Cell>{u.invoiceNo}</Table.Cell>
                <Table.Cell>{u.plan}</Table.Cell>
                <Table.Cell>{u.amount / 100}</Table.Cell>
                <Table.Cell>{u.currency}</Table.Cell>
                <Table.Cell>
                  <Button
                    size="small"
                    color="info"
                    onClick={() => downloadHandler(u)}
                  >
                    {loading ? 'Generating PDF...' : 'Download now!'}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
export default InvoiceData;
