import { Column, Title, Notification } from 'rbx';
import React, { useEffect } from 'react';
import Transactions from './components/Transactions';
import { css } from 'emotion';
import useSubscriptions from '../../hooks/useSubscriptions';
import useUser from '../../hooks/useUser';
import InfoPanel from './components/InfoPanel';

function SubscriptionPage() {
  const { user } = useUser();
  const subs = useSubscriptions();

  useEffect(() => {
    if (user) {
      subs.getTransaction(user.id, user?.billingVersion);
    } else {
      subs.resetTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      subs.getSubscription(user.id);
    } else {
      subs.resetSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      className={css`
        padding: 1em 0em;
      `}
    >
      <header
        className={css`
          padding: 0.2em 0em;
        `}
      >
        <Title size={3}>Subscription</Title>
      </header>
      {user && (
        <Column.Group>
          {subs.subscriptionError ? (
            <Column size="two-thirds">
              <Notification
                className={css`
                  padding: 0.5em;
                `}
                color="danger"
              >
                {subs.subscriptionError}
              </Notification>
            </Column>
          ) : (
            <Column size="two-thirds">
              <InfoPanel />
            </Column>
          )}
        </Column.Group>
      )}
      {!user && (
        <Column.Group>
          <Column size="two-thirds">
            <p>You have no active subscription</p>
          </Column>
        </Column.Group>
      )}
      <Transactions />
    </div>
  );
}

export default SubscriptionPage;
