import React, { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'cookies-js';
import PropTypes from 'prop-types';
import add from 'date-fns/add';

const AuthContext = createContext();

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function AuthProvider({ children, value }) {
  const auth = useAuthProvider(value);
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

// Provider hook that creates auth object and handles state
function useAuthProvider(_token) {
  const [token, setToken] = useState(_token);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (!token) {
      Cookies.expire('AuthToken');
      setIsLoading(false);
    } else {
      // TODO: get expiry date from token
      // For now, use app default 30 days
      const expiryDate = add(new Date(), { days: 30 });
      Cookies.set('AuthToken', token, {
        expires: expiryDate,
      });
      getUser(token)
        .then(user => setUser(user))
        .catch(err => setError(err.message))
        .finally(() => setIsLoading(false));
    }
  }, [token]);

  // Get the current user
  async function getUser(token) {
    // TODO
    return {};
  }

  // Requests a sign-in link
  async function login(email, password) {
    let payload = {
      email,
      origin: window.location.origin,
      os: 'N/A',
      browser: 'N/A',
    };
    if (password) payload.password = password;
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (res.status > 399) {
      const txt = await res.text();
      throw new Error(txt);
    }
    return password ? res.json() : true;
  }

  // Verifies a login token
  async function verifyLogin(tempToken) {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/verify-login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: tempToken,
        }),
      },
    );
    const body = await res.text();
    if (res.status > 399) {
      throw new Error(body);
    }
    const { token } = JSON.parse(body);
    setToken(token);
    return true;
  }

  // Logs a user out
  async function logout() {
    setToken(null);
  }

  // Return the user object and auth methods
  return {
    user,
    error,
    isLoading,
    login,
    verifyLogin,
    logout,
  };
}

// Hook for child components to get the auth object
// and re-render when it changes.
const useAuth = () => useContext(AuthContext);
export default useAuth;
