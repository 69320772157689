import * as API from '../lib/api';

import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

const UserContext = createContext();

// Provider component that wraps your app and makes user object
// available to any child component that calls useUser().
export function UserProvider({ children, value }) {
  const user = useUserProvider(value);
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

// Provider hook that creates user object and handles state
function useUserProvider(_userId) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Gets a user by ID
  async function getUserById(id) {
    setIsLoading(true);
    setError(null);
    try {
      const user = await API.getUserById(id);
      setUser(user);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  // Gets the user ID by email
  async function getUserByEmail(email) {
    setIsLoading(true);
    setError(null);
    try {
      const user = await API.getUserByEmail(email);
      setUser(user);
      return user;
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  // Updates a user field
  const updateUserField = (userId, key, val) =>
    API.updateUserField(userId, key, val);

  // Changes a user's primary email
  const changeEmail = (userId, email) => API.changeEmail(userId, email);

  // Changes a user's role
  const changeRole = (userId, role) => API.changeRole(userId, role);

  // Return the user object and user methods
  return {
    user,
    setUser,
    getUserById,
    getUserByEmail,
    updateUserField,
    changeEmail,
    changeRole,
    isLoading,
    error,
  };
}

// Hook for child components to get the user object
// and re-render when it changes.
const useUser = () => useContext(UserContext);
export default useUser;
