import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Loading } from './';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

// A wrapper for <Route> that gets auth and profile info,
// and redirects to login screen if user is not logged in.
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  if (auth.isLoading) {
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
