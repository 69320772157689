import { Column, Navbar } from 'rbx';
import { Logo } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function BaseLayout({ children }) {
  const auth = useAuth();

  async function logoutHandler() {
    await auth.logout();
    window.location.href = '/';
  }
  return (
    <div
      className={css`
        padding: 0.75em;
      `}
    >
      <Column.Group>
        <Column>
          <Navbar
            className={css`
              border-top: 3px solid #167ee6;
            `}
          >
            <Navbar.Brand>
              <Navbar.Item>
                <Logo />
              </Navbar.Item>
            </Navbar.Brand>
            <Navbar.Menu>
              <Navbar.Segment align="start">
                <Navbar.Item as={Link} to="/">
                  Dashboard
                </Navbar.Item>
                <Navbar.Item as={Link} to="/user">
                  Search
                </Navbar.Item>
                <Navbar.Item as={Link} to="/invoice">
                  Invoice
                </Navbar.Item>
                {/* <Navbar.Item as={Link} to="/export-invoices">
                  Export Invoice
                </Navbar.Item> */}
              </Navbar.Segment>
            </Navbar.Menu>
            <Navbar.Menu>
              <Navbar.Segment align="end">
                <Navbar.Item onClick={logoutHandler}>
                  <FontAwesomeIcon
                    className={css`
                      font-size: 20px;
                    `}
                    icon={Icons.faSignOutAlt}
                  />{' '}
                  {/* <Generic as="span" textColor="danger">
                    Logout
                  </Generic> */}
                </Navbar.Item>
              </Navbar.Segment>
            </Navbar.Menu>
          </Navbar>
          <br />
          <Column>{children}</Column>
        </Column>
      </Column.Group>
    </div>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
