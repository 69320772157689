import { css } from 'emotion';
import { Title, Column } from 'rbx';
import React, { useEffect } from 'react';
import Resumes from './components/Resumes';
import useUser from '../../hooks/useUser';
import useResumes from '../../hooks/useResumes';

function ResumesPage() {
  const { user } = useUser();
  const { resumes, listResumes, setResumes } = useResumes();

  useEffect(() => {
    if (user) {
      if (!resumes.length) {
        listResumes(user.id);
      }
    } else {
      setResumes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      className={css`
        padding: 1em 0em;
      `}
    >
      <header
        className={css`
          padding: 0.2em 0em;
        `}
      >
        <Title size={3}>Resumes</Title>
      </header>

      {user ? (
        <Column.Group>
          <Column size="two-thirds">
            <Resumes />
          </Column>
        </Column.Group>
      ) : (
        <Column.Group>
          <Column size="two-thirds">
            <p>No Resume found</p>
          </Column>
        </Column.Group>
      )}
    </div>
  );
}

export default ResumesPage;
