import { Column, Title } from 'rbx';
import React from 'react';
import BaseLayout from '../../layouts/base';
import UseInfo from './components/UserInfo';

function UserPage() {
  return (
    <BaseLayout>
      <Column.Group>
        <Column size="full">
          <Title size={3}>User</Title>
          {/* <Title subtitle size={4}>
          This page allows you to view user's data
        </Title> */}
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="one-thirds">
          <UseInfo />
        </Column>
      </Column.Group>
    </BaseLayout>
  );
}
export default UserPage;
