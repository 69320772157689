import { Button, Control, Field, Help, Input, Title } from 'rbx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import isUUID from 'is-uuid';
import useUser from '../hooks/useUser';

function UserSearch() {
  const { user, isLoading, getUserById, getUserByEmail, error } = useUser();
  const [value, setValue] = useState(user ? user.id : '');
  const changeHandler = e => setValue(e.target.value);
  const history = useHistory();
  async function submitHandler(e) {
    e.preventDefault();
    // Check if value is a UUID (user ID) or email
    // and use the corresponding user query function.
    const isUserId = isUUID.anyNonNil(value);
    const getUser = isUserId ? getUserById : getUserByEmail;
    await getUser(value);
    history.push({
      pathname: '/account',
      search: `?email=${isUserId ? value : encodeURIComponent(value)}`,
    });
  }

  return (
    <>
      <Title size={5}>Find a user</Title>
      <form onSubmit={submitHandler}>
        <Field kind="addons" horizontal expanded>
          <Control expanded>
            <Input
              disabled={isLoading}
              placeholder="Search by user ID or email"
              value={value}
              onChange={changeHandler}
              color={error ? 'danger' : ''}
            />
            {error && <Help color="danger">{error}</Help>}
          </Control>
          <Control>
            <Button type="submit" color="primary" disabled={isLoading}>
              Search
            </Button>
          </Control>
        </Field>
      </form>
    </>
  );
}

export default UserSearch;
