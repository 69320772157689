import {
  createAPIRequest,
  createBillingRequest,
  createWebappRequest,
  createCoverLettersRequest,
  createAirtableRequest,
} from './_requests';

export const getUserById = id =>
  createAPIRequest({
    method: 'GET',
    path: '/user',
    query: {
      id,
    },
  });

export const getUserByEmail = email =>
  createAPIRequest({
    method: 'GET',
    path: '/user',
    query: {
      email,
    },
  });

export const deleteAccount = userId =>
  createAPIRequest({
    method: 'DELETE',
    path: '/user',
    userId,
  });

export const listResumes = userId =>
  createAPIRequest({
    method: 'GET',
    path: '/resume',
    userId,
  });

export const getResume = (userId, resumeId) =>
  createAPIRequest({
    method: 'GET',
    path: `/resume/${resumeId}`,
    userId,
  });

export const listCoverLetters = userId =>
  createCoverLettersRequest({
    method: 'GET',
    path: '/cover-letters',
    userId,
  });

export const getCoverLetter = (userId, coverLetterId) =>
  createCoverLettersRequest({
    method: 'GET',
    path: `/cover-letters/${coverLetterId}`,
    userId,
  });

export const getDocumentHtml = ({ file, source = 'resume', gender }) =>
  createWebappRequest({
    method: 'POST',
    path: '/api/export-html',
    body: {
      file,
      source,
      redneg: gender,
    },
  });

export const exportDocument = ({
  html,
  template,
  color,
  fileType = 'pdf',
  gender,
}) =>
  createAPIRequest({
    method: 'POST',
    path: '/resume/export-pdf',
    body: {
      html,
      fileType,
      template,
      color,
      gender,
    },
  });

export const updateUserField = (userId, key, value) =>
  createAPIRequest({
    method: 'PATCH',
    path: '/user',
    body: {
      key,
      value,
    },
    userId,
  });

export const changeEmail = (userId, email) =>
  createAPIRequest({
    method: 'PATCH',
    path: '/user/change-email',
    body: {
      email,
    },
    userId,
  });

export const changeRole = (userId, role) =>
  createAPIRequest({
    method: 'PATCH',
    path: '/user/change-role',
    body: {
      role,
    },
    userId,
  });

export const createMagicLink = userId =>
  createAPIRequest({
    method: 'POST',
    path: '/auth/create-magic-link',
    body: {
      userId,
    },
  });

export const getSubscription = userId =>
  createBillingRequest({
    method: 'GET',
    path: '/subscription?needCardInfo=true',
    userId,
  });

// Cancel at end of billing period
export const cancelSubscription = (userId, immediately = false) =>
  createBillingRequest({
    method: 'DELETE',
    path: '/subscription',
    userId,
    body: {
      immediately,
    },
  });

// Lists all transactions for a subscription
export const listTransactions = userId =>
  createBillingRequest({
    method: 'GET',
    path: '/transactions',
    userId,
  });

// refund a transaction
export const refundTransaction = (userId, transactionId) =>
  createBillingRequest({
    method: 'POST',
    path: `/transaction/refund/${transactionId}`,
    userId,
  });

// search user
export const searchUser = (value, from, size) =>
  createAPIRequest({
    method: 'GET',
    path: `/es/user/?value=${value}&from=${from}&size=${size}`,
  });

// Lists all invoice for a transactions
export const listInvoice = userId =>
  createAPIRequest({
    method: 'GET',
    path: `/payment-invoice/${userId}`,
  });

// Lists all invoice by emailId
export const listInvoiceByEmailId = emailId =>
  createAPIRequest({
    method: 'GET',
    path: `/payment-invoice/email/${emailId}`,
  });

//get company data for invoice
export const getCompanyInfo = name =>
  createAirtableRequest({
    method: 'GET',
    path: `/Companies Details?filterByFormula=SEARCH("${name}",{Name})`,
  });

// Get invoices by the date range filter
export const getInvoiceByDateRange = dateRange =>
  createAPIRequest({
    method: 'POST',
    path: `/task-processor/export-invoices`,
    body: dateRange,
  });

// Get task by task ID for Pooling
export const getTaskResultByTaskId = taskId =>
  createAPIRequest({
    method: 'GET',
    path: `/task-processor/${taskId}`,
  });
