import * as API from '../lib/api';

import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const CoverLettersContext = createContext();

// Provider component that wraps your app and makes user object
// available to any child component that calls useCoverLetters().
export function CoverLettersProvider({ children, value }) {
  const coverLetters = useCoverLettersProvider(value);
  return (
    <CoverLettersContext.Provider value={coverLetters}>
      {children}
    </CoverLettersContext.Provider>
  );
}

CoverLettersProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

function useCoverLettersProvider(_coverLetter) {
  const [coverLetters, setCoverLetters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);

  // list cover letters for user
  async function listCoverLetters(userId) {
    setIsLoading(true);
    setError(null);
    try {
      // list cover letters
      let coverLetters = await API.listCoverLetters(userId);
      coverLetters.sort((a, b) => {
        a = new Date(a.createdAt);
        b = new Date(b.createdAt);
        if (a === b) return 0;
        if (a > b) {
          return -1;
        } else {
          return 1;
        }
      });
      setCoverLetters(coverLetters);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadCoverLetter(userId, coverLetterId) {
    setIsDownloading(true);
    setError(null);
    try {
      const coverLetter = await API.getCoverLetter(userId, coverLetterId);
      if (coverLetter) {
        const html = await API.getDocumentHtml({
          file: coverLetter,
          source: 'cover-letter',
        });
        if (html) {
          const {
            settings: { template, color },
          } = coverLetter;
          const buffer = await API.exportDocument({ html, template, color });
          const file = new Blob([buffer], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      } else {
        throw new Error('Cover letter not found');
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsDownloading(false);
    }
  }

  return {
    coverLetters,
    isLoading,
    isDownloading,
    setCoverLetters,
    listCoverLetters,
    downloadCoverLetter,
    error,
  };
}

// Hook for child components to get the user object
// and re-render when it changes.
const useCoverLetters = () => useContext(CoverLettersContext);
export default useCoverLetters;
