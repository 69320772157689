import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'rbx/index.css';
import App from './App';
import { AuthProvider } from './hooks/useAuth';
import Cookies from 'cookies-js';
import { SubscriptionProvider } from './hooks/useSubscriptions';
import { UserProvider } from './hooks/useUser';
import { ResumesProvider } from './hooks/useResumes';
import { CoverLettersProvider } from './hooks/useCoverLetters';
import reportWebVitals from './reportWebVitals';
import { InvoiceProvider } from './hooks/useInvoice';

const authToken = Cookies.get('AuthToken');
const root = document.getElementById('root');

ReactDOM.render(
  <AuthProvider value={authToken}>
    <InvoiceProvider>
      <UserProvider value={null}>
        <SubscriptionProvider>
          <ResumesProvider>
            <CoverLettersProvider>
              <App />
            </CoverLettersProvider>
          </ResumesProvider>
        </SubscriptionProvider>
      </UserProvider>
    </InvoiceProvider>
  </AuthProvider>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
