import PropTypes from 'prop-types';
import React from 'react';
import { Tag } from 'rbx';

const getTagColor = role => {
  switch (role) {
    case 'paid_user':
      return 'success';
    case 'admin':
      return 'warning';
    default:
      return 'light';
  }
};

function UserRoleTag({ role }) {
  return <Tag color={getTagColor(role)}>{role}</Tag>;
}

UserRoleTag.propTypes = {
  role: PropTypes.string.isRequired,
};

UserRoleTag.defaultProps = {
  role: 'user',
};

export default UserRoleTag;
