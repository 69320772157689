import { Column, Title } from 'rbx';
import React from 'react';
import BaseLayout from '../../layouts/base';
import InvoiceInfo from './components/InvoiceInfo';

function InvoicePage() {
  return (
    <BaseLayout>
      <Column.Group>
        <Column size="full">
          <Title size={3}>Invoice</Title>
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="one-thirds">
          <InvoiceInfo />
        </Column>
      </Column.Group>
    </BaseLayout>
  );
}
export default InvoicePage;
